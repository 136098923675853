import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AuthenticatedRoutes from "components/routes/authenticatedRoutes/authenticatedRoutes";
import UnauthenticatedRoutes from "components/routes/unauthenticatedRoutes/unauthenticatedRoutes";
import stompManager from "hooks/stompManager";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "store";
import useUserStore from "store/userStore";
import "./App.css";
import { Toaster } from "components/ui/toaster";


function App() {
  const client = new QueryClient();
  const isLoggedIn = useUserStore((state: any) => state.isLoggedIn);
  useEffect(() => {
    stompManager.connect();
  }, []);


  useEffect(() => {
    if (Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        console.log(`Notification permission: ${permission}`);
       
      });
    } else {
      console.log(`Notification permission: ${Notification.permission}`);
    }
  }, []);

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={client}>
            <Router>
              <AuthenticatedRoutes />

              {/* <UnauthenticatedRoutes/> */}
            </Router>
            <Toaster />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
