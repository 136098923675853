"use client";
import { Button } from "components/ui/button";
import {
  IconBell,
  IconDoorExit,
  IconVolumeOff,
  IconVolume,
} from "@tabler/icons-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useUserStore, { UserState } from "store/userStore";
import HamburgerMenu from "../hamburgerMenu/hamburgerMenu";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/userSlice";
import { useEffect, useState } from "react";
import { getNotifications } from "api/notificationController";
import stompManager from "hooks/stompManager";
import ClockComponent from "../timers/clock";
import { closeNotificationSound, openNotificationSound } from "store/notificationSlice";

const Topbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const notificationSoundActive = useSelector(
    (state: any) => state.notification.notificationSoundOpen
  );
  const pageTitle = pathname?.split("/")[2]?.replace(/-/g, " ");
  const logoutZust = useUserStore((state: any) => state.logout);
  const dispatch = useDispatch();
  const [notificationsNumber, setNotificationsNumber] = useState<any>(0);
  const user = useSelector((state: UserState) => state.user.user);
  const destination =
    user?.role === "MASTER_ADMIN"
      ? `/topic/merchant/notifications/admin`
      : `/topic/merchant/notifications/${user?.merchantId}`;
  const audio = new Audio("../sounds/notification.wav");

  useEffect(() => {
    stompManager.subscribe(destination, handleNewNotification);
  }, []);

  const playNotificationSound = () => {
    audio.play();
  };


  const handleNewNotification = (response: any) => {
    //todo if (response.body) {
    //   console.log(response?.body);
    //   playNotificationSound();
    //   setNotificationsNumber(notificationsNumber + 1);
    //   // const newTransaction = JSON.parse(response.body);
    //   // addNewTransaction(newTransaction);
    // }
  };

  const handleLogout = async () => {
    await logoutZust();
    dispatch(logout());
    navigate("/login");
  };
  useEffect(() => {
    const fetchUnreadNotificationsNumber = async () => {
      try {
        const response = await getNotifications();
        const notifications = response?.content;
        //count the notifications where notification.isRead is not true, return just the number
        const unreadNotifications = notifications?.filter(
          (notification: any) => !notification?.isRead
        );

        setNotificationsNumber(unreadNotifications?.length);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUnreadNotificationsNumber();
  }, []);

  return (
    <div className="flex items-center justify-between bg-[#212632] p-4 capitalize dark:text-white sticky top-0">
      <h2 className="text-xl lg:text-3xl">PAYMENT VAULT</h2>
      <div className="flex items-center gap-2">
        <ClockComponent />

        {!notificationSoundActive ? <IconVolumeOff onClick={()=>dispatch(openNotificationSound())} style={{cursor:"pointer"}}/> : <IconVolume onClick={()=>dispatch(closeNotificationSound())} style={{cursor:"pointer"}}/>}

      
        <Link to={"/home/notifications"}>
          <div className="relative mr-2">
            <IconBell />
            <div
              className={`${notificationsNumber > 0 ? "scale-100" : "scale-0"} absolute right-[-4px] top-[-6px] flex h-4 w-4 items-center justify-center rounded-full bg-red-500 duration-300`}
            >
              <span className="text-xs">{notificationsNumber}</span>
            </div>
          </div>
        </Link>
        <HamburgerMenu />
        <Button
          variant={"outline"}
          className="gap-2 border-yellow-700"
          onClick={handleLogout}
        >
          <IconDoorExit /> Log Out
        </Button>
      </div>
    </div>
  );
};

export default Topbar;
