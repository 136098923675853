import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationSoundOpen: true,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    openNotificationSound(state) {
      state.notificationSoundOpen = true;
    },
    closeNotificationSound(state) {
      state.notificationSoundOpen = false;
    },
  },
});

export const { openNotificationSound, closeNotificationSound } = notificationSlice.actions;

export default notificationSlice.reducer;
