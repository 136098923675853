import React, { useState, useEffect } from "react";

const ClockComponent: React.FC = () => {
  const [time, setTime] = useState<string>("");

  useEffect(() => {
    const updateClock = () => {
      const now = new Date();
      const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: "America/Halifax", 
        hour12: false, 
      };

      const formatter = new Intl.DateTimeFormat("en-US", options);
      const formattedTime = formatter.format(now);

      setTime(formattedTime);
    };

    updateClock(); 
    const intervalId = setInterval(updateClock, 1000);

    return () => clearInterval(intervalId); 
  }, []);

  return <div className="hidden sm:block">{time}</div>;
};

export default ClockComponent;
