import { Button } from "components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "components/ui/dialog";
import React, { Dispatch, SetStateAction, useState } from "react";
import { createCryptoConversionSettlement } from "api/cryptoController";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import axios from "axios";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "components/ui/input";
import { Textarea } from "components/ui/textarea";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { IconEdit } from "@tabler/icons-react";
import { NumericFormat } from "react-number-format";
import {
  formatStringDigitToNumber,
  formatNumberAsDigit,
  removeCommas,
} from "lib/utils";
import { updateZelleById } from "api/transactions";

interface updateZelleDialogProps {
  zelledData: any;
  refreshSettlements: Dispatch<SetStateAction<boolean>>;
  isAnyModalOpen: Dispatch<SetStateAction<boolean>>;
}

const UpdateZelleDialog: React.FC<updateZelleDialogProps> = ({
  zelledData,
  refreshSettlements,
  isAnyModalOpen
}) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const [errorMessage, setErrorMessage] = useState<string>("");
  const closeModal = () => {
    setIsOpen(false);
    isAnyModalOpen(false)
    setErrorMessage("");
  };

  const formSchema = z.object({
    client: z.string().min(1).max(70),
    amount: z.string().min(1).max(70),
    memo: z.string().min(1).max(70),
    operation: z.string().min(1).max(70),
    startDate: z.string().min(1).max(70),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      client: zelledData?.client || "",
      amount: zelledData?.amount || "",
      memo: zelledData?.memo || "",
      operation: zelledData?.operation || "",
      startDate: formatDateTimeLocal(zelledData?.startDate) || "",
    },
  });
  const { reset } = form;

  const handleDialogOpen = (isOpen: boolean) => {
    setIsOpen(isOpen);
    // isAnyModalOpen(true)
    if (isOpen) {
     
      reset({
        client: zelledData?.client || "",
        amount: zelledData?.amount || "",
        memo: zelledData?.memo || "",
        operation: zelledData?.operation || "",
        startDate: zelledData?.startDate
          ? formatDateTimeLocal(zelledData?.startDate)
          : "",
      });

    } else {
      closeModal();
    }
  };

  
  async function onSubmit(values: z.infer<typeof formSchema>) {
    const payload = {
      client: values.client,
      amount: removeCommas(formatStringDigitToNumber(values.amount).toString()),
      memo: values.memo,
      operation: values.operation,
      startDate: values.startDate
    };

    const zelleId = zelledData?.id;

    try {
      await updateZelleById(payload, zelleId).then((res) => {
        if (res) {
          closeModal();
          refreshSettlements((prev) => !prev);
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorDetails = error.response?.data?.details;
        setErrorMessage(
          `ERROR: Failed to update transaction data!  ${errorDetails || error.message}`
        );
      } else {
        setErrorMessage(
          "An unexpected error occurred. Please try again later."
        );
      }
    }
  }

  function formatDateTimeLocal(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  return (
    <Dialog open={isOpen} 
    onOpenChange={handleDialogOpen}
    >
      <DialogTrigger>
        <Button
          variant={"secondary"}
          className="h-fit w-fit bg-yellow-500 p-2 font-bold text-black hover:border-yellow-500 hover:text-white"
        >
          <IconEdit size={14} />
        </Button>
      </DialogTrigger>
      <DialogContent className="dark max-h-[80vh] w-full overflow-auto dark:text-white ">
        <Form {...form}>
          <div className="flex justify-center">
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form?.control}
                name="client"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="dark:text-white">Client</FormLabel>
                    <FormControl>
                      <Input className="dark:text-white" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex flex-col justify-between gap-6">
                <FormField
                  control={form.control}
                  name="amount"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="flex items-center gap-4">
                        Amount
                      </FormLabel>
                      <FormControl>
                        <NumericFormat
                          {...field}
                          customInput={Input}
                          thousandSeparator
                          decimalScale={3}
                          allowNegative={false}
                          className="w-[280px] lg:w-[350px] text-right text-xl"
                          onValueChange={(values) => {
                            field.onChange(+values.value);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form?.control}
                name="memo"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="dark:text-white">Memo</FormLabel>
                    <FormControl>
                      <Input className="dark:text-white" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="startDate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Date</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        type="datetime-local"
                        className="w-full bg-slate-500 text-white"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form?.control}
                name="operation"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="dark:text-white">Role</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        value={field?.value}
                      >
                        <SelectTrigger className="w-full bg-slate-500 text-white">
                          <SelectValue
                            style={{ textDecorationColor: "white" }}
                            placeholder="select operation"
                          />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="RECEIVED">RECEIVED</SelectItem>
                          <SelectItem value="CANCEL">CANCEL</SelectItem>
                          <SelectItem value="REQUEST">REQUEST</SelectItem>
                          <SelectItem value="WRONG EMAIL">
                            WRONG EMAIL
                          </SelectItem>
                          <SelectItem value="WRONG PLATFORM">
                            WRONG PLATFORM
                          </SelectItem>
                          <SelectItem value="DELAYED">DELAYED</SelectItem>
                          <SelectItem value="PENDING">PENDING</SelectItem>
                          <SelectItem value="UNKNOWN OPERATION">
                            UNKNOWN OPERATION
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="text-red-500">{errorMessage}</div>
              <div className={`flex pb-12`}>
                <Button
                  variant={"secondary"}
                  className="w-[280px] lg:w-[350px] border bg-yellow-500 font-bold text-black hover:border-yellow-500 hover:text-white"
                >
                  Update Zelle Transaction{" "}
                </Button>
              </div>
            </form>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateZelleDialog;
