import axios from "axios";
import { useToast } from "components/ui/use-toast";
import store from "store";
import { logout } from "store/userSlice";


const baseUrl = process.env.REACT_APP_API_URL;

export const REST = axios.create({
  baseURL: baseUrl,
  timeout: 100000,
  headers: {
    accept: "*/*",
    "Content-Type": `application/json`,
  },
});

export const RESTMultipart = axios.create({
  baseURL: baseUrl,
  timeout: 100000,
  headers: {
    accept: "*/*",
    "Content-Type": `multipart/form-data`,
  },
});

export const RESTLinks = axios.create({
  baseURL: baseUrl,
  timeout: 100000,
  headers: {
    accept: "*/*",
    responseType: "arraybuffer",
  },
});

RESTLinks.interceptors.request.use(
  async (config) => {
    const state = store.getState() as any;
    const accessToken = state.user?.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

REST.interceptors.request.use(
  async (config) => {
    const state = store.getState() as any;
    const accessToken = state.user?.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

RESTMultipart.interceptors.request.use(
  async (config: any) => {
    const state = store.getState() as any;

    const accessToken = state.user?.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

REST.interceptors.response.use(
  response => response,

  async error => {

    const originalRequest = error.config;
    
    if (error?.response?.status === 401 && !originalRequest._retry) {
      console.log("err")
      
      const state = store.getState() as any;
      store.dispatch(logout());
      window.location.replace("/login")
      
    
    }
    return error;
  }
)
