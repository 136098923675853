import { IconLoader } from "@tabler/icons-react";
import {
  TaskStatus,
  Transaction,
  TransactionType,
  getTransactions,
  updateTransactionStatus,
} from "api/transactions";
import stompManager from "hooks/stompManager";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { UserState } from "store/userStore";
import { useLocation, useNavigate } from "react-router-dom";
import { useHandleAxiosError } from "hooks/useAxiosHandlerError";
import ResponsiveTableWithPagination from "components/shared/customTableComponent/ResponsiveTableWithPagination";
import StatusSelect from "components/shared/statusSelect/statusSelect";
import { Input } from "components/ui/input";
import { formatDateWithHours, formatNumberAsDigit } from "lib/utils";
import NavigationButtons from "components/shared/buttons/buttonsList/navigationButtonsList";
import { NavigationButtonsData } from "interfaces/navigationButtonsListInterface";
import { Roles } from "roles/roles";
import ExcelReport from "components/excelReport/excelReport";
import UpdateZelleDialog from "components/forms/updateZelle/updateZelleDialog";
import PaymentVaultIcon from "../../../../images/vaultIcon.png";

const Transactions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: UserState) => state.user.user);
  const notificationState = useSelector(
    (state: any) => state.notification.notificationSoundOpen
  );
  const audioPath = "../sounds/notification.wav";
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isWarning, setIsWarning] = useState(false);
  const { handleAxiosErrors } = useHandleAxiosError();
  // State variables
  const [data, setData] = useState<any>();
  const queryParams = new URLSearchParams(location.search);
  const [merchantId, setMerchantId] = useState<number>(
    user?.role === Roles?.MASTER_ADMIN
      ? queryParams.get("merchantId")
      : queryParams.get("merchantId")
        ? queryParams.get("merchantId")
        : user.merchantId
  );

  const [isPageVisible, setIsPageVisible] = useState(!document.hidden);

  const destination = `/topic/merchant/${merchantId}`;
  const [notificationCooldown, setNotificationCooldown] = useState(false);
  const [clientName, setClientName] = useState(
    queryParams.get("client") ? queryParams.get("client") : ""
  );
  const [isLoading, setIsLoading] = useState(true);
  const [sendNotificationsWS, SetSendNotificationsWS] = useState(false);
  const [isAnyModalOpen, setISModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [transactionType, setTransactionType] =
    useState<TransactionType>("new");

  let buttonsList: NavigationButtonsData = [];

  buttonsList = [
    { buttonName: "New", buttonType: "secondary", buttonValue: "new" },
    {
      buttonName: "Confirmed",
      buttonType: "secondary",
      buttonValue: "processed",
    },
    {
      buttonName: "Canceled",
      buttonType: "secondary",
      buttonValue: "canceled",
    },
    {
      buttonName: "Pending",
      buttonType: "secondary",
      buttonValue: "pending",
    },
  ];

  const allowedStatusChange = ["NEW", "PROCESSED"];

  const columns: any[] = [
    {
      Header: "Date",
      accessor: "startDate",
      Cell: ({ value }: any) => (value ? formatDateWithHours(value) : null),
    },
    {
      Header: "Client",
      accessor: "client",
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ value }: any) => formatNumberAsDigit(value),
    },
    {
      Header: "Memo",
      accessor: "memo",
    },
    {
      Header: "Operation",
      accessor: "operation",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value, row }: any) => (
        <>
          {user?.role === Roles?.MASTER_ADMIN ? (
            <div className="flex flex-row items-center gap-3">
              {allowedStatusChange.includes(value) && (
                <UpdateZelleDialog
                  zelledData={row.original}
                  refreshSettlements={setRefreshData}
                  isAnyModalOpen={setISModalOpen}
                />
              )}

              <StatusSelect
                onChange={(value) => handleStatusChange(row.original.id, value)}
                currentStatus={row.original.status}
              />
            </div>
          ) : (
            <>
              {allowedStatusChange.includes(value) ? (
                <StatusSelect
                  onChange={(value) =>
                    handleStatusChange(row.original.id, value)
                  }
                  currentStatus={row.original.status}
                />
              ) : (
                value
              )}
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    let originalTitle = document.title;
    let blinkInterval: any;

    const startBlinking = () => {
      let showingWarning = true;

      blinkInterval = setInterval(() => {
        document.title = showingWarning
          ? "⚠️ New Zelle Transaction!"
          : originalTitle;
        showingWarning = !showingWarning;
      }, 1500);
    };
    const stopBlinking = () => {
      clearInterval(blinkInterval);
      document.title = originalTitle;
    };

    if (isWarning && isPageVisible === false) {
      startBlinking();
    } else {
      setIsWarning(false);
      stopBlinking();
    }

    return () => stopBlinking();
  }, [isWarning, isPageVisible]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsPageVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (sendNotificationsWS === true) {
      sendNotifications();
    }
  }, [sendNotificationsWS]);

  const refreshPagination = () => {
    setPageSize(10);
    setCurrentPage(0);
  };

  const getTransactionsQuery = async () => {
    try {
      const response = await getTransactions(
        pageSize,
        currentPage,
        transactionType,
        merchantId,
        clientName
      );
      return response;
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const sendNotifications = () => {
    if (notificationState === true) {
      if (isPageVisible === false) {
        setIsWarning(true);
      }

      if (Notification.permission === "granted") {
        new Notification("New Zelle Transaction", {
          icon: PaymentVaultIcon,
          body: "You have a new Zelle transaction!",
        });
      }

      if (audioRef.current) {
        audioRef.current.play().catch((error) => {
          console.error("Audio playback failed:", error);
        });
      }
    }
    SetSendNotificationsWS(false);
  };

  const handleTransactionTypeChange = (type: any) => {
    setTransactionType(type);
    refreshPagination();
    navigate(
      `/home/transactions?transactionType=${type}$merchantId=${merchantId}&client=${clientName}`
    );
  };

  const handleStatusChange = async (
    transactionId: number,
    status: TaskStatus
  ) => {
    try {
      await updateTransactionStatus(transactionId, status).then((res) => {
        if (res) {
          setRefreshData((prev) => !prev);
        }
      });
    } catch (error) {
      handleAxiosErrors({
        toastTitle: "Failed to change transaction status!",
        error: error,
      });
    }
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      setIsLoading(true);
      const response = await getTransactionsQuery();
      setData(response);
      setIsLoading(false);
    };

    fetchTransactions();
  }, [pageSize, currentPage, refreshData, transactionType, merchantId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRefreshData((prev) => !prev);
    }, 1200);
    return () => clearTimeout(timer);
  }, [clientName]);

  useEffect(() => {
    const timer = setTimeout(() => {
      // if (isAnyModalOpen === false) {
      setRefreshData((prev) => !prev);
      // }
    }, 90000);
    return () => clearTimeout(timer);
  }, [refreshData]);

  const handleNewTransaction = (response: any) => {
    if (response.body) {
      SetSendNotificationsWS(true);
      setRefreshData((prev) => !prev);
    }
  };

  useEffect(() => {
    stompManager.subscribe(destination, handleNewTransaction);
  }, [merchantId, notificationState]);

  const handleMerchantId = (value: number) => {
    setMerchantId(value);
    navigate(
      `/home/transactions?transactionType=${transactionType}&merchantId=${value}&client=${clientName}`
    );
  };

  const TransactionSection = () => (
    <div className="mt-4 flex flex-grow flex-col justify-between pb-8">
      {isLoading ? (
        <IconLoader size={72} className="m-auto mt-14 animate-spin" />
      ) : (
        <ResponsiveTableWithPagination
          columns={columns}
          data={data}
          handlePageChange={setCurrentPage}
        />
      )}
    </div>
  );

  return (
    <div className="ml-2 mr-2 mt-4 flex flex-grow dark:bg-black dark:text-white">
      <audio ref={audioRef} src={audioPath} preload="auto" />
      <div className="mt-1 flex flex-grow flex-col rounded-lg bg-[#212632]">
        <div className="hidden md:flex items-center justify-between border-b border-b-slate-500 p-4">
          <h2 className="text-xl">
            View, edit, or delete your
            <span className="font-bold text-yellow-500"> transactions</span>
          </h2>
        </div>

        <div className="flex items-center flex-col justify-between p-3 md:flex-row justify-center gap-2">
          <ExcelReport reportType="ZELLE" handleMerchantId={handleMerchantId} />
          <Input
            value={clientName ? clientName : ""}
            onChange={(e) => {
              setClientName(e.target.value);
              navigate(
                `/home/transactions?transactionType=${transactionType}$merchantId=${merchantId}&client=${e.target.value}`
              );
            }}
            type="text"
            name="clientName"
            placeholder="Filter by client name"
          />
          <div className="flex flex-row items-center md:flex-row">
            <div className="flex justify-center md:justify-end p-2 ">
              <NavigationButtons
                buttonsList={buttonsList}
                value={transactionType}
                setValue={handleTransactionTypeChange}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-grow flex-col p-4">
          {/* <SearchForm handlePageSizeChange={handlePageSizeChange} /> */}
          <TransactionSection />
        </div>
      </div>
    </div>
  );
};

export default Transactions;
