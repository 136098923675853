import { Client, IMessage, StompSubscription } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

interface Subscriber {
  destination: string;
  callback: (message: IMessage) => void;
}

class StompManager {
  private url: string;
  private client: Client | null = null;
  private subscribers: Subscriber[] = [];
  private subscriptions: { [destination: string]: StompSubscription | null } = {};

  constructor(url: string) {
    this.url = url;
  }

  connect() {
    const socket = new SockJS(this.url);

    this.client = new Client({
      webSocketFactory: () => socket as WebSocket,
      onConnect: () => {
        console.log('STOMP connection established');
        this.subscribers.forEach(({ destination, callback }) => {
          this.subscriptions[destination] = this.client?.subscribe(destination, callback) || null;
        });
      },
      onDisconnect: () => {
        console.log('STOMP connection closed');
      },
      onStompError: (frame) => {
        console.error('STOMP error:', frame);
      },
    });

    this.client.activate();
  }

  subscribe(destination: string, callback: (message: IMessage) => void) {
    if (this.client && this.client.connected) {
      // Subscribe immediately if the connection is established
      const subscription = this.client.subscribe(destination, callback);
      this.subscriptions[destination] = subscription;
    } else {
      // Store subscriber information to subscribe later
      this.subscribers.push({ destination, callback });
    }
  }

  unsubscribe(destination: string) {
    // Unsubscribe if there is a valid subscription
    const subscription = this.subscriptions[destination];
    if (subscription) {
      subscription.unsubscribe();
      delete this.subscriptions[destination]; // Remove the subscription reference
      console.log(`Unsubscribed from ${destination}`);
    } else {
      console.warn(`No active subscription found for ${destination}`);
    }
  }
}

const stompManager = new StompManager(`${process.env.REACT_APP_API_URL}/websocket`);

export default stompManager;
